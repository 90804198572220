<template>
    <div id="body-content">	
	
        <!-- Section: Mini Hero -->
        <div class="section-mini-hero" style="background-color: gray">
            <div class="container">
                <h1 class="title1 mini-hero-title">{{ $t('allPost.title')}} </h1>
            </div>
        </div>
        <!-- /Section: Mini Hero -->

        <!-- SECTION: BLog Feed -->
        <div class="section-blog">
            <div class="container">
                <div class="section-blog">
                    <div class="row">
                        <div class="col-md-9">	
                            <div class="blog-posts" v-for="(post, index) in posts" :key="index">
                                <div class="blog-item">
                                    <div class="post-thumbnail">
                                        <a @click="goToSinglePost(post.postId)" class="click-fade"><img v-lazy="{src: post.img}" alt="" class="img-all-post"></a>
                                    </div>
                                    <div class="post-content">
                                        <div class="row">
                                            <div class="col-sm-1 post-archive-infos-wrapper">
                                                <div class="post-archive-infos">
                                                    <span class="post-date"></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-11">
                                                <h3 class="post-title title-border"><a @click="goToSinglePost(post.postId)" class="title-all-post">{{post.title}}</a></h3>
                                                <div class="post-text">
                                                    <p>{{post.shortDescription}}</p>
                                                </div>
                                                <a @click="goToSinglePost(post.postId)" class="btn btn-1c click-fade">{{ $t('allPost.readMore')}}&nbsp;&nbsp;<i class="fa fa-arrow-right"></i></a>
                                            </div>
                                        </div>									
                                    </div>									
                                </div>
                            </div>			
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="section-pagination">
                                        <div class="row">
                                            <div class="col-xs-4">
                                                <a href="#">&lt;&lt; {{ $t('allPost.previous') }}</a>
                                            </div>
                                            <div class="col-xs-4 text-center">
                                                <span class="pagination-numbers">
                                                    <a href="#">1</a>
                                                    <a href="#">2</a>
                                                    <a href="#">3</a>
                                                    <a href="#">4</a>
                                                    <a href="#">5</a>
                                                    <a href="#">6</a>
                                                </span>
                                            </div>
                                            <div class="col-xs-4 text-right">
                                                <a href="#">{{ $t('allPost.next') }} &#62;&#62;</a>
                                            </div>	
                                        </div>								
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">		
                            <div class="blog-sidebar">
                                <div class="sidebar-module sidebar-tags">
                                    <h3 class="module-title">Tags</h3>
                                    <div class="tags">
                                        <a href="#">{{ $t('allPost.tagDesign') }}</a>
                                        <a href="#">{{ $t('allPost.tagBuild') }}</a>
                                        <a href="#">{{ $t('allPost.tagCreate') }}</a>
                                        <a href="#">{{ $t('allPost.tagOutside') }}</a>
                                        <a href="#">{{ $t('allPost.tagPlanProject') }}</a>
                                        <a href="#">{{ $t('allPost.tagDesignFunction') }}</a>
                                        <a href="#">{{ $t('allPost.tagFunction') }}</a>
                                        <a href="#">{{ $t('allPost.tagCreatePool') }}</a>
                                    </div>
                                </div>
                                <!-- <div class="sidebar-module sidebar-archives">
                                    <h3 class="module-title">Archives</h3>
                                    <ul class="archives-list">
                                        <li><a href="#"><span class="fa fa-caret-right"></span> January 2015</a></li>
                                        <li><a href="#"><span class="fa fa-caret-right"></span> September 2014</a></li>
                                        <li><a href="#"><span class="fa fa-caret-right"></span> August 2014</a></li>
                                        <li><a href="#"><span class="fa fa-caret-right"></span> July 20134</a></li>
                                    </ul>		
                                </div> -->
                                <div class="sidebar-module">
                                    <h3 class="module-title">{{ $t('allPost.ourProjects')}}</h3>
                                    <div class="sidebar-module-content">
                                        <p>{{ $t('allPost.seemoreOurProjects')}}</p>	
                                    </div>				
                                    <a href="archive-projects.php" class="btn click-fade">{{ $t('allPost.seemoreProjects')}}</a>	
                                </div>
                            </div>									
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /SECTION: Blog feed -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            posts: [
                {
                    postId: 1,
                    img: require('../../src/assets/post-1.webp'),
                    title: this.$t('allPost.titlePost1'),
                    shortDescription: this.$t('allPost.shortDescriptionPost1')
                },
                {
                    postId: 2,
                    img: require('../../src/assets/post-2.webp'),
                    title: this.$t('allPost.titlePost2'),
                    shortDescription: this.$t('allPost.shortDescriptionPost2')
                },
                {
                    postId: 3,
                    img: require('../../src/assets/post-3.webp'),
                    title: this.$t('allPost.titlePost3'),
                    shortDescription: this.$t('allPost.shortDescriptionPost3')
                },
                {
                    postId: 4,
                    img: require('../../src/assets/post-4.webp'),
                    title: this.$t('allPost.titlePost4'),
                    shortDescription: this.$t('allPost.shortDescriptionPost4')
                },
            ]
        }
    },
    methods: {
        goToSinglePost(PostId) {
            window.scrollTo(0,0)
            this.$router.push ({name: 'single-post', params:{PstId:PostId}})
        },
    }
}
</script>